import React from "react";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import SEO from "@src/components/SEO";
import window from "global/window";
import { incrementMetric } from "@src/utils/logClient";

const Photobook: React.FC = () => {
  useRedirectHandler(() => {
    if (typeof window === "undefined" || !window.location) {
      return;
    }
    const search = new URLSearchParams(window.location.search);

    if (!search.has("classID")) {
      incrementMetric("external_site.universal_link.fallback", { component: "Photobook" });
      return `https://home.classdojo.com/#/photobook-default?expect-free=${search.get("expect-free")}`;
    }

    if (search.get("target") === "order" && search.has("photobookID")) {
      return `https://home.classdojo.com/#/photobook/${search.get("classID")}/finish/${search.get("photobookID")}`;
    }

    return `https://home.classdojo.com/#/photobook/${search.get("classID")}/start`;
  });

  return <SEO noindex={true} />;
};
export default Photobook;
